import BoundingBox from '@/services/api/models/BoundingBox';

export abstract class Constants {
  public static readonly ALLPRODUCTBOUNDINGBOX: BoundingBox = {
    northEast: { lat: 80, lon: 180 },
    southWest: { lat: -80, lon: -180 }
  };
  // Countries
  public static readonly IRELAND = 'Ireland';
  public static readonly NEWZEALAND = 'New Zealand';

  // Products
  public static readonly PRODUCT_TYPE_MILK = 'milk';
  public static readonly PRODUCT_TYPE_FUEL = 'fuel';
  public static readonly PRODUCT_TYPE_FEED = 'feed';
  public static readonly PRODUCT_TYPE_WATER = 'water';
  public static readonly PRODUCT_TYPE_GENERIC = 'generic';

  // Api Entity
  public static readonly API_ENTITY_TYPE_MILK = 'vats';
  public static readonly API_ENTITY_TYPE_FUEL = 'tanks';
  public static readonly API_ENTITY_TYPE_FEED = 'feed_tanks';
  public static readonly API_ENTITY_TYPE_WATER_TANK = 'water_tanks';
  public static readonly API_ENTITY_TYPE_WATER_METER = 'water_meters';
  public static readonly API_ENTITY_TYPE_PRODUCT = 'products';

  // STATUS
  public static readonly ENTITY_STATUS_LIVE = 'live';
  public static readonly ENTITY_STATUS_SIGNOFF = 'sign-off';
  public static readonly ENTITY_STATUS_INSTALL = 'install';
  public static readonly ENTITY_STATUS_PENDING = 'pending';
  public static readonly ENTITY_STATUS_DISPLAY_LIVE = 'live';
  public static readonly ENTITY_STATUS_DISPLAY_SIGNOFF = 'awaiting sign-off';
  public static readonly ENTITY_STATUS_DISPLAY_INSTALL = 'pending install';
  public static readonly ENTITY_STATUS_DISPLAY_PENDING = 'requested';
  public static readonly FAULT_STATUS_REPAIR = 'repair';

  // Fault impact
  public static readonly FAULT_IMPACT_NO_DATA = 'No Data';
  public static readonly FAULT_IMPACT_UNRELIABLE_DATA = 'Unreliable Data';
  public static readonly FAULT_IMPACT_MAINTENANCE =
    'No Impact (Scheduled Maintenance)';

  // Compliance methods
  public static readonly COMPLIANCE_METHOD_FONTERRA = 'fonterra';
  public static readonly COMPLIANCE_METHOD_MPI = 'mpi';
  public static readonly COMPLIANCE_METHOD_SAMR = 'samr';
  public static readonly COMPLIANCE_METHOD_NOTSET = 'not set';

  // Units
  public static readonly UNIT_LITRES = 'litres';
  public static readonly UNIT_TONNES = 'tonnes';
  public static readonly UNIT_KILOGRAM = 'kilogram';

  // Metric
  public static readonly METRIC_VOLUME = 'volume';
  public static readonly METRIC_WEIGHT = 'weight';

  // Entity Label
  public static readonly ENTITY_LABEL_TANK = 'tank';
  public static readonly ENTITY_LABEL_VAT = 'vat';
  public static readonly ENTITY_LABEL_BIN = 'bin';
  public static readonly ENTITY_LABEL_SILO = 'silo';
  public static readonly ENTITY_LABEL_METER = 'meter';
  public static readonly ENTITY_LABEL_MONITOR = 'monitor';
}

export function getUnitChar(unit: string) {
  switch (unit) {
    case Constants.UNIT_TONNES:
      return 't';
    case Constants.UNIT_KILOGRAM:
      return 'kg';
    default:
      return 'L';
  }
}

export function getBootstrapColourHex(name: string) {
  return window
    .getComputedStyle(window?.document?.documentElement)
    .getPropertyValue(`--${name}`)
    ?.trim();
}
