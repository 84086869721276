import User from '@/store/modules/User';
import { Constants } from './Constants';

export function getCountry() {
  return (
    User._token?.orgs[User._orgIndex].address?.country ?? Constants.NEWZEALAND
  );
}

export function getLevnoPhoneNumber() {
  const phoneNumber: { [key: string]: string } = {
    [Constants.IRELAND]: '1800 234 453',
    [Constants.NEWZEALAND]: '0800 453 866'
  };
  return phoneNumber[getCountry()];
}

export async function getCountryCode() {
  const lookup = await require('country-code-lookup');

  const country = getCountry();
  return lookup.byCountry(country).iso2;
}
